<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="!isCreated ? '编辑' : '新增'"
    :visible.sync="show"
    width="500px"
  >
    <div class="content">
      <el-form
        label-width="120px"
        :model="form"
        size="mini"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="二级分类名称" prop="clazzName">
          <el-input v-model="form.clazzName" placeholder="请输入二级分类名称" />
        </el-form-item>
        <el-form-item label="所属一级分类" prop="parentId">
          <el-select clearable v-model="form.parentId">
            <el-option
              v-for="item in clazzList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  props: ['clazzList'],
  mixins: [dialogCommonParams],
  data() {
    const _this = this

    return {
      rules: {
        clazzName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入二级分类名称'
          }
        ],
        parentId: [
          { required: true, trigger: 'change', message: '请选择一级分类' }
        ]
      },
      form: {
        clazzName: '',
        parentId: null
      },
      loading: false
    }
  },
  created() {
    if (this.currentItem) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.currentItem[key]
      })
    }
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            const params = { ...this.form }

            if (!this.isCreated) {
              params.id = this.currentItem.id
              await this.$api.clazzList.updateClazz(params)
              this.$message.success('编辑成功')
            } else {
              await this.$api.clazzList.addClazz(params)
              this.$message.success('添加成功')
            }
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
