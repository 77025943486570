<template>
  <page>
    <template slot="headerRight">
      <el-button
        v-auth="'goodsClazz:add'"
        @click="
          addVisible = true
          currentItem = null
        "
        type="primary"
      >
        添加
      </el-button>
      <!-- <el-button @click="delHandler(false)" :disabled="!multipleSelection.length" type="danger"> 批量删除 </el-button> -->
    </template>

    <template slot="headerLeft">
      <el-form @submit.native.prevent ref="filtersForm" inline :model="params">
        <el-form-item label="二级分类" prop="clazzName">
          <el-input
            v-model="params.clazzName"
            placeholder="二级分类"
          ></el-input>
        </el-form-item>
      </el-form>
    </template>

    <div class="mainWrap">
      <div class="leftGroup">
        <h3 style="margin-bottom: 6px">商品一级分类</h3>
        <el-tree
          @node-click="nodeClick"
          default-expand-all
          :highlight-current="true"
          :current-node-key="1"
          :data="treeData"
          node-key="id"
        >
          <template #default="{ node }">
            <span class="custom-tree-node">
              <span>{{ node.label }}</span>
            </span>
          </template>
        </el-tree>
      </div>
      <div class="table-wrap">
        <el-table
          @selection-change="handleSelectionChange"
          :data="tableData"
          v-loading="loading"
          height="100%"
        >
          <!-- <el-table-column  type="selection" width="55" /> -->
          <el-table-column show-overflow-tooltip prop="id" label="二级分类ID" />
          <el-table-column
            show-overflow-tooltip
            prop="clazzName"
            label="二级分类名称"
          />
          <el-table-column
            show-overflow-tooltip
            prop="parentName"
            label="所属一级分类"
          />
          <el-table-column
            show-overflow-tooltip
            prop="updateTime"
            label="修改时间"
          />
          <el-table-column width="220" label="操作">
            <template slot-scope="{ row }">
              <el-button
                v-auth="'goodsClazz:update'"
                @click="editHandler(row)"
                type="text"
                size="mini"
                >编辑</el-button
              >
              <el-button
                v-auth="'goodsClazz:delete'"
                @click="delHandler(row.id)"
                type="text"
                size="mini"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <AddModal
      v-if="addVisible"
      @getData="getData(true)"
      :visible.sync="addVisible"
      :clazzList="clazzList"
      :current-item="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      addVisible: false,
      groupVisible: false,
      storeGroupVisible: false,
      currentItem: null,
      treeData: [
        {
          label: '全部分类',
          id: 1,
          showGroup: true,
          children: []
        }
      ],
      tableData: [],
      memberList: [],
      multipleSelection: [],
      clazzList: [],
      params: {
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  created() {
    this.getClazzList()
    this.getData()
  },
  components: {
    AddModal
  },
  methods: {
    nodeClick(data) {
      this.params.parentId = data.label == '全部分类' ? undefined : data.id
      this.getData()
    },
    addGroup() {
      this.groupVisible = true
      this.currentItem = null
    },
    treeEditHandler(nodeData) {
      this.groupVisible = true
      this.currentItem = nodeData
    },
    async getClazzList() {
      try {
        const res = await this.$api.clazzList.getClazz({})

        if (res.clazzList) {
          let clazz = res.clazzList.list || []

          this.clazzList = clazz.map(item => ({
            label: item.clazzName,
            value: item.id
          }))

          this.treeData[0].children = clazz.map(item => ({
            label: item.clazzName,
            id: item.id
          }))
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        const res = await this.$api.clazzList.getClazzList(params)
        if (res.secondaryClazzList && res.secondaryClazzList.list) {
          this.tableData = res.secondaryClazzList.list.map(item => {
            return item
          })
        }

        this.totalCount = res.secondaryClazzList.total
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.id)
    },

    async delHandler(id) {
      try {
        await this.$confirm(
          '删除分类后，该二级分类下的商品将只关联到一级分类，是否确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )

        try {
          this.loading = true

          await this.$api.clazzList.delClazz({
            id
          })
          this.$message.success('删除成功')
          this.getData()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    editHandler(row) {
      this.currentItem = row
      this.addVisible = true
    },
    storeGroup(row) {
      this.currentItem = row
      this.storeGroupVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.mainWrap {
  display: flex;
  flex: 1;
  .leftGroup {
    padding: 6px;
    width: 250px;
    border: 1px solid #eee;

    .addGroup {
      padding: 3px 8px !important;
    }
  }

  .operate i {
    font-size: 16px;
  }

  ::v-deep .custom-tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
  }
}
</style>
